import { v4 as uuidv4 } from 'uuid'
import jwt from 'jsonwebtoken'
import store from '../store/index'
import { menuItemsCompleto, menuItemsSinEstancias, menuItemsDefault} from '../data/navigation'
import { find } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as moment from 'moment'

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios' 
export const baseUrl = process.env.VUE_APP_BASE_URL_API



const currentDate = () => {
    let date = new Date();
    // adjust 0 before single digit date
    let day = ("0" + date.getDate()).slice(-2);
    // current month
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
    let year = date.getFullYear()

    return year + "-" + month + "-" + day
}

const formatDate = (fecha, locale) => {

    const date = new Date(fecha + 'T00:00:00')

    const mesesSpanish = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    const mesesIngles = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dia = date.getDate()
    const mes = date.getMonth()
    const yyy = date.getFullYear()

    const stringEs = dia + '  ' + mesesSpanish[mes] + ' ' + yyy
    const stringEn = mesesIngles[mes] + ' ' + dia + ', ' + yyy

    const stringDate = locale == "es" ? stringEs : stringEn

    return stringDate
}



const checkItemsCartSihotCode = (cart) => {
    let resultados = cart.filter(item => item.serviciosihot == null)
    if (resultados.length > 0) {
        store.commit('shop/setMakeRoomCharge', true, { root: true })
        store.commit('shop/setManualRoomChargeCliente', true, { root: true })
        return { ok: true, objetos: resultados }
    } else {
        store.commit('shop/setMakeRoomCharge', false, { root: true })
        store.commit('shop/setManualRoomChargeCliente', false, { root: true })
        return { ok: false, objetos: null }
    }

}




const stringAleatorio = () => {    
    return uuidv4() 
}
const validUserLogin = () => {
    const isLoggedUser = store.state.auth.isLogged
    const hasSalesChannel = store.state.shop.canalventa
    const hasPointOfSales = store.state.shop.puntoventa
    if (isLoggedUser && hasSalesChannel && hasPointOfSales) {
        return true
    } else {
        return false
    }
}

const generateMenuList = ( seccionCanalUser ) => {         
    const hasVendorCode =  store.state.auth.user?.canalventa?.dingusvendorcode || false        
    if( seccionCanalUser != 0 ){        
        if( seccionCanalUser == 6 || seccionCanalUser == 29  ){ 
            return hasVendorCode ? menuItemsCompleto : menuItemsSinEstancias            
        } else {
            return menuItemsDefault          
        }  
    } else {
        return menuItemsDefault
    }    
}

const validateDataInCart = (cart) => {
    const itemFind = find(cart, { requiredFieldsFilled: false })
    if (itemFind) {
        Vue.$toast({
            component: ToastificationContent,
            props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: `💡 Existe por lo menos algún elemento sin completar sus datos, asegurese de completar esa información.`,
                variant: 'danger',
            },
        }, {
            position: 'bottom-right',
            timeout: 4000,
        })
        return true
    } else {
        return false
    }
}

const camposRequeridosPorLLenar = (product) => {
    let camposFaltantes = []    
    const { categoryName, itinerario, operationdate, NamePrefix, GivenName, Surname, PhoneNumber, Email, term, typeDiscount, isIncludenIn, requiresAuthorization } = product
    if (categoryName == "Transfer") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
        if (!itinerario.ifrom || itinerario.ifrom == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Origen" })
        }
        if (!itinerario.ito || itinerario.ito == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Destino" })
        }
        if (!itinerario.pax || itinerario.pax == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Pax" })
        }
        if (!itinerario.trip || itinerario.trip == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Tipo de servicio" })
        }
    }

    if (categoryName == "Reservas") {
        if (!NamePrefix || NamePrefix == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Prefijo" })
        }
        if (!GivenName || GivenName == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Nombre" })

        }
        if (!Surname || Surname == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Apellido" })
        }
        if (!PhoneNumber || PhoneNumber == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Número de télefono" })
        }
        if (!Email || Email == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Email" })
        }
        if (!term || term == null) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Termino y condición" })
        }
        if (requiresAuthorization) {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Falta autorizar el booking" })

        }
    }

    if (categoryName != "Transfer" && categoryName != "Reservas") {
        if (!operationdate || operationdate == '') {
            camposFaltantes.push({ key: stringAleatorio(), campo: "Date" })
        }
    }
    if (typeDiscount == 'Incluido en el paquete' && !isIncludenIn) {
        camposFaltantes.push({ key: stringAleatorio(), campo: "Reserva donde se incluye" })
    }
    return camposFaltantes
}
const toDecimal = (stringToDecimal) => {
    return String((Math.round(stringToDecimal * 100) / 100).toFixed(2))
}

const toJson = (stringToJson) => {
    return JSON.parse(JSON.stringify(stringToJson))
}

const showAlertMessage = (title = 'Ok', icon = 'BellIcon', text, variant = 'success', timeout = '3000', position = 'bottom-right') => {
    Vue.$toast({
        component: ToastificationContent,
        props: { title, icon, text, variant },
    }, { timeout, position })
}



const consoleMsgFinally = (action, message) => {
    if (process.env.NODE_ENV == 'development' ) {
        console.log(`[${action}] ${message}`)
    }
}


const formatItems = ( products, type ) => {
    if( type === 'products'){        
        return formatListProducts( products ) 
    }
    if( type === 'events'){        
        return formatListEvents( products ) 
    } 
    if( type === 'packages'){
        console.log('entre aqui')
        return formatListPackage( products ) 
    }        
}

const formatListProducts = ( products ) => {
    let items = []
    products.forEach((product) => {        
        product.modelType = 'product'
        product.categoryName = product.category.name || ''
        product.detailSelected = product.detail[0] ? product.detail[0] : null
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.type
        delete product.category
        delete product.productinfo
        delete product.detailId 

        if(product.detailSelected){
            items.push(product) 
        }     
    })

    return items
}

const formatListPackage = ( packages ) => {
    packages.forEach((product) => {
        product.modelType = 'package'         
        product.detailSelected = {
            descmax: 0,
            detaildisplay: product.name,
            id: product.id,
            saleprice: product.saleprice,
        }
        delete product.products
        delete product.gallery
        delete product.videolink
        delete product.weblink
        delete product.category
        delete product.productinfo
    })

    return packages
}

const formatListEvents = ( events ) => {
    events.forEach((product) => {
        const timeInitFormat = product.startTime.slice(0, -3)
        const timeEndFormat = product.endTime.slice(0, -3)
        const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
        product.modelType = 'event'
        product.categoryName = 'Events'                       
        const detalleIngles = product.eventdetail[1]
        product.detailSelected = {
            descmax: detalleIngles ? parseInt(detalleIngles.descmax) : 0,
            detaildisplay: product.name,
            id: detalleIngles ? detalleIngles.id : product.id,
            saleprice: product.saleprice,
            
        }
        product.startDateFormat = formatDate(product.startDate, 'en')
        product.endDateFormat = formatDate(product.endDate, 'en')
        product.startTimeFormat = String(timeInitFormat) + ' ' + ampmInit
        product.endTimeFormat = String(timeEndFormat) + ' ' + ampmEnd
        product.currencyCode = product.currency.code
        product.hotelName = product.hotel.name
        product.locationName = product.location.name
        product.description = detalleIngles ? detalleIngles.description : ''
        delete product.eventdetail
        delete product.gallery
        delete product.currency
        delete product.hotel
        delete product.location
        delete product.category
        delete product.productinfo
    })

    return events
}

const setParamsGetProducts = ( parametros, idseccanal ) => {
    const { type, language }  = parametros        
    if( language && type === 'products'){
        return { language, idseccanal }
    }else {
        if(type === 'products'){
           return { bs: 1, idseccanal } 
        }
        if(type !== 'products'){
           return { idseccanal }
        }
    }
}

const formatItemProduct = (item, type) => {
    if ( type == 'packages') {
        return formatPackage(item)            
    }
    if ( type == 'products') {
        return formatProduct(item)            
    }

    if ( type == 'events') {
        return formatEvent(item)
    }
}
const formatPackage = (item) => {
    item.categoryName = 'Packages'
    item.modelType = 'package'    
    item.detaildisplay = item.name
    if (item.gallery) {
        item.gallery.forEach((item) => {
            item.key = stringAleatorio()
        })
    }
    if (item.products) {
        item.products.forEach((item) => {
            item.keyItem = stringAleatorio()
        })
    }
    item.detailSelected = {
        cost: item.cost || 0,
        descmax: 0,
        detailDesc: item.name,
        detaildisplay: item.name,
        
        id: item.id,
        saleprice: item.saleprice, 

    }
    return item
}

const formatProduct = (item) => {
    item.categoryName = 'Products'
    item.modelType = 'product'
    item.experience = null
    item.experiencecost = 0
    item.experienceType = 1 
    item.experienceContract = null 

    // item.uuid = stringAleatorio()
    // item.operationtime = '12:00'
    item.detailSelected = item.detail[0] ? item.detail[0] : null

    if( item.detailSelected ){
        item.detailSelected.detailId = item.detailSelected.id
        delete item.detailSelected.currency
        delete item.detailSelected.seccionventaid
    }

    item.detailId = item.detailSelected.id
    item.saleprice = item.detailSelected.saleprice
    item.blackoutDates = {
        disable : [],
        maxDate : '',
        minDate : currentDate()
    }
  
    delete item.productavailability
    delete item.rulesProduct
    delete item.productinfo
    delete item.gallery
    delete item.destination
    delete item.detail
    delete item.products
    delete item.supplier
    delete item.supplierPromo
    delete item.videolink
    delete item.weblink    

    return item
}

const formatEvent = event => {

    event.categoryName = 'Events'
    event.modelType = 'event'
    event.hotelName = event.hotel.name
    event.description = event.name
    event.detailDesc = event.name
    event.detaildisplay = event.name
    event.locationName = event.location?.name || ''

    event.experience = null
    event.experiencecost = 0
    event.experienceType = 1 
    event.experienceContract = null 

    const timeInitFormat = event.startTime.slice(0, -3)
    const timeEndFormat = event.endTime.slice(0, -3)
    const ampmInit = (timeInitFormat.slice(0, -3) >= 12) ? "PM" : "AM"
    const ampmEnd = (timeEndFormat.slice(0, -3) >= 12) ? "PM" : "AM"
  
    event.startTimeFormatted = String(timeInitFormat) + ' ' + ampmInit
    event.endTimeFormatted = String(timeEndFormat) + ' ' + ampmEnd
    event.operationdate = event.startDate
    event.operationtime = timeInitFormat

    event.detailSpanish = event.eventdetail.find((item) => item.languaje.code === "SPA") || null
    event.detailEnglish = event.eventdetail.find((item) => item.languaje.code === "ENG") || null

    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    const startDate = event.startDate+'T06:00-05:00'
    const endDate = event.endDate+'T06:00-05:00'
    
    event.spanishDates = {
        startDate : new Date(startDate).toLocaleDateString("es-MX", optionsDate),
        endDate : new Date(endDate).toLocaleDateString("es-MX", optionsDate),
    }

    event.englishDates = {
        startDate : new Date(startDate).toLocaleDateString("en-US", optionsDate),
        endDate : new Date(endDate).toLocaleDateString("en-US", optionsDate),
    }

    event.blackoutDates = {
        disable : [],
        maxDate : '',
        minDate : currentDate()
    }
    
    event.detailSelected = {
        cost: event.cost || 0,
        descmax: 0,
        detailDesc: event.name,
        detaildisplay: event.name,
        id: event.id,
        saleprice: event.saleprice,   
    }    
    
    if( event.detailSpanish ){
        delete event.detailSpanish.displaycurrency
        delete event.detailSpanish.languaje
    }
    if( event.detailEnglish ){
        delete event.detailEnglish.displaycurrency
        delete event.detailEnglish.languaje
    }
    delete event.currency
    delete event.location
    delete event.hotel
    delete event.gallery
    delete event.hotel
    delete event.eventdetail

    return event
}

const formatExperience = experience => {

    experience.categoryName = experience.category ? experience.category.name : 'Experiences'
    experience.modelType = 'experience'
    experience.detailDesc = experience.displayName
    experience.detaildisplay = experience.displayName
    experience.detailId = experience.experienceId
   
    experience.blackoutDates = {
        disable : [],
        maxDate : '',
        minDate : currentDate()
    }
    let myRates = []
    
    if( experience.rates ){
        
        const ratesTypeOne = experience.rates.find( (rate) => rate.type === 1 && rate.num > 0 )
        const ratesTypeTwo = experience.rates.find( (rate) => rate.type === 2  && rate.num > 0 )
        const ratesTypeThree = experience.rates.find( (rate) =>  rate.type === 3  && rate.num > 0 )

        if(ratesTypeOne){
            myRates.push(ratesTypeOne)
        }
        if(ratesTypeTwo){
            myRates.push(ratesTypeTwo)
        }
        if(ratesTypeThree){
            myRates.push(ratesTypeThree)
        }


        myRates.forEach( (rate) => {
            rate.detaildisplay = experience.displayName 
            rate.saleprice = rate.amount,
            rate.subTotal = 0
            rate.isBenefit =  false

            if( rate.type === 1 ){
                rate.typeDisplay = 'Adults'
                rate.qty = rate.num
            }

            if( rate.type === 2 ){
                rate.typeDisplay ='Children'
                rate.qty = rate.num || 0
            }

            if( rate.type === 3 ){
                rate.typeDisplay = 'Group'
                rate.qty = rate.num
            }
            rate.subTotal = rate.total
            // sum = rate.type === 3 ? experience.total : sum + rate.total

        })
        
        experience.total = experience.total
        experience.saleprice = experience.total

    }

    experience.detail  = myRates
    experience.detailSelected = myRates.length > 0 ? myRates[0] : null

    delete experience.rates

    delete experience.supplierPromo
    delete experience.termsandconditions
    delete experience.supplier
    delete experience.parameterList
    delete experience.notificationTo

    return experience
}

const overWriteAxiosHeaders = (queryParams) =>{
    //sobreescribo los datos en axios, una vez obtenido un token
    axios.defaults.headers.common['Authorization'] = 'Token '+ queryParams?.token
    axios.defaults.baseURL = baseUrl
    Vue.use(VueAxios, axios)// end    
}

const decodeJwt = ( stringJwt ) => {
    try {
        const decoded = jwt.verify(stringJwt, process.env.VUE_APP_JWT_SECRET )
        return {ok: true, ...decoded }
    } catch (error) {
        return { ok: false, msg: `${error.name}: ${error.message} ` }
    }
}
const makePayloadRequestProducts = items => {
    let requests = []
    items.forEach(item => {        
        const { type, product, detail } = item
        requests.push({idProducto: parseInt(product), idProductDetail:parseInt(detail), type, endPoint:type, language: null})
    })
    return requests
}

const generateProductsToAddCart = (itemsQueryParams, productsState) => {    
    let listCart = []
    itemsQueryParams.forEach( productItem  => {
        const { detail: idDetail, product } = productItem // propiedades de los items en queryParamsState  
        const productState = productsState.find( p => p.id === product ) //ubico el producto en el state de Products                 
        
        if(productState){
            const { detail, modelType } = productState
    
            if( modelType !== 'product' ){
                delete productState.detail
                delete productState.detailSelected.currency
                listCart.push(productState)
            }
            if( modelType === 'product' ){                
              const itemDetail = detail.find( detalle => detalle.id == idDetail)                     
              if( itemDetail ){ // si existe detalle, lo tomo y armo sus propiedades
                const { descmax, detaildisplay, extrapax, id, saleprice } = itemDetail
                productState.descmax = parseInt(descmax) || 0
                productState.detaildisplay = detaildisplay   
                productState.extrapax = extrapax
                productState.detailId = id
                productState.saleprice = saleprice
                delete productState.detail
                delete productState.detailSelected.currency
                listCart.push(productState)
              }            
            }
        }              
    })
    return listCart
}

const removeNullValuesFromAnObject = (object) => {
    Object.keys(object).forEach(key => {
        if ( object[key] === null || object[key] === '') {
          delete object[key];
        }
    })    
    return object
}

export {
    formatProduct,
    formatEvent,
    formatExperience,
    validUserLogin,
    generateMenuList,
    currentDate,
    formatDate,
    validateDataInCart,    
    checkItemsCartSihotCode,
    stringAleatorio,
    camposRequeridosPorLLenar,
    toDecimal,
    toJson,
    showAlertMessage,
    consoleMsgFinally,    
    formatListProducts,
    formatListPackage,
    formatListEvents,
    formatItems,
    setParamsGetProducts,
    formatItemProduct,
    overWriteAxiosHeaders,   
    decodeJwt,
    makePayloadRequestProducts,
    generateProductsToAddCart,
    removeNullValuesFromAnObject
}