// import isAuthenticatedGuard from '../../auth/router/auth-guard'

export default [    
    {
        path: '/sales/products',
        name: 'sales',
        component: () => import('@/modules/shop/views/Experiencies'),        
        meta: {
            // requiresAuth: true,
            // contentRenderer: 'sidebar-left-detached',
            contentClass: 'ecommerce-application',            
        },
    },
    {
        path: '/sales/products/:slug',
        name: 'sales-product-details',
        component: () => import('@/modules/shop/views/DetailExperience'),
        meta: {
            // requiresAuth: true,
        },
        props: ( route ) => {
            return {
                slug: route.params.slug
            }
        },        
    },    
    {
        path: '/sales/order',
        name: 'order-summary',
        component: () => import('@/modules/shop/views/OrderSummary'),   
        // beforeEnter: isAuthenticatedGuard,        
             
        meta: {
            // requiresAuth: true,
            contentClass: 'ecommerce-application',
        },
    },
    {
        path: '/sales/payment',
        name: 'payment',
        component: () => import('@/modules/shop/views/Checkout'),                
        meta: {
            // requiresAuth: true,
            contentClass: 'ecommerce-application',
        },
    },
      
    {
        path: '/sales/bankresponse',
        name: 'bank-response',
        component: () => import ('@/modules/shop/views/BankResponse'),
        meta: {
            layout: 'full',
        },
    }, 
    {
        path: '/sales/paymentlanding',
        name: 'payment-response',
        component: () => import ('@/modules/shop/views/PaymentLanding'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/sales/paymentbooking',
        name: 'paymentbook-response',
        component: () => import ('@/modules/shop/views/PaymentBooking'),
        meta: {
            layout: 'full',
        },
    },
   
    {
        path: '/sales/termsConditions',
        name: 'termsConditions',
        component: () => import ('@/modules/shop/views/TermsConditions'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/payment/response',
        name: 'paymentresponse',
        component: () => import ('@/modules/shop/views/Response.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },    
]