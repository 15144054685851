import Vue from 'vue'
import { finishStepTotalZeroDL } from '../../../../helpers/dataLayerHelpers'
import { showAlertMessage, consoleMsgFinally, toJson } from '../../../../helpers/helpers'
import { processItemsCart, createPayloadToSave } from '../../../../helpers/shopHelper'
import {  fetchHotels } from '../../../start/store/actions'


export async function getInitialContentCheckout( context ){  
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }                        
    const content = await Promise.all([
        fetchHotels(payloadHotels) // solo necesito listado de hoteles
    ])    
    const [ hotels ] = content    
    context.commit('start/setHotels', hotels, { root: true }) //muto state hotels en start        
    return content
}

export async function checkIfClientExists({ commit }, cliente) {
    const client = toJson(cliente)

    try {
        commit('shop/setLoadingProcess', true, { root: true })
        const response = await Vue.axios({
            url: `/customers/?email=${client.email}`,
        })
        const clientData = response && response.data
        const id = clientData.id || false
        if (id) {
            Vue.swal({
                title: `<p style="font-size:.7em;">This email already exists: <br> ${clientData.email} </p>`,
                text: `Do you want to use this information?`,
                icon: 'warning',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                customClass: {                
                    confirmButton: 'mr-1',        
                }
            }).then((result) => {
                if (result.value) {
                    commit('shop/setInfoClient', clientData, { root: true })
                    showAlertMessage('Notification', 'BellIcon', '📧 Information has been taken to complete the data in the form. .', 'success', 6000, 'bottom-right')
                } else {
                    commit('shop/unsetEmailCliente', '', { root: true })
                }
            })
        }
    } catch (error) {
        console.log(error.response)
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/checkIfClientExists', 'La petición para verificar si el cliente se ha terminado')
    }
}

export async function sendInfoClient({ commit, state, getters }, cliente) {

    const client = toJson(cliente)  
    const orderCreated = state.orderCreated || false    
    const { paymentmethod } = client //método de pago del cliente con desestructuración, para usar despues
    try {
        commit('shop/setLoadingProcess', true, { root: true })                     
        const response = await Vue.axios({
            method: 'POST',
            url: '/customers/',
            data: client
        })
        const clientData = response && response.data                        
        //armo el array details con los datos solicitados con los elementos del cart
        const detail = processItemsCart( state.cart.items, clientData)        
        //objeto con los datos del client y detalles con los valores del cart (payload)                        
        const payload = createPayloadToSave(client, state, getters, detail, clientData)
        // console.log(payload, orderCreated )
        // return 
        if (orderCreated) {
            const order = { order: orderCreated }
            await retryPayment({ commit }, order)
        } else {
            await sendCartItems({ commit }, payload)
        }

    } catch (error) {
        if (paymentmethod == 4) {
            commit('shop/setMakeRoomCharge', true, { root: true })
            commit('shop/setManualRoomChargeCliente', true, { root: true })
        }
        console.log(error)
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/sendInfoClient', 'La petición para enviar los datos del cliente se ha terminado')
    }
}

export async function retryPayment({ commit }, idOrder) {

    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/paymentretry/',
            data: idOrder
        })

        const respuesta = response && response.data
        const { payment, order, invoice } = respuesta

        if (order) { //si hay orden creada seteo el valor            
            commit('shop/setOrderCreated', order, { root: true })
        }

        if (payment) {
            const { error, url } = payment
            //sino hay error true y url viene, se redirecciona a formulario de pago
            if (!error && url != '') {
                window.location = url
            }
        } else {
            finishStepTotalZeroDL() // si aparece el alert mando los datos al dataLayer, antes de que se borre el state
            commit('shop/setEmptyCart', [], { root: true }) // [] cart se vacía, se resetean datos 
            commit('shop/unsetLastNameSearch', '', { root: true })            
            Vue.swal.fire('Operación finalizada', `🎉🎉 La orden ha sido completada: ${invoice}. 🎉🎉`, 'success')
        }

    } catch (error) {
        console.log(error)
    } finally {
        console.log('retryPayment end')
    }
}

export async function sendCartItems({ commit }, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: '/orders/',
            data: payload
        })
        const respuesta = response && response.data
        const { payment, order, invoice } = respuesta
        if (order) { //si hay orden creada seteo el valor            
            commit('shop/setOrderCreated', order, { root: true })
        }
        if (payment) {
            const { error, url } = payment
            //sino hay error true y url viene, se redirecciona a formulario de pago
            if (!error && url != '') {
                window.location = url
            }
        } else {            
            finishStepTotalZeroDL() // si aparece el alert mando los datos al dataLayer, antes de que se borre el state
            commit('shop/setEmptyCart', [], { root: true })
            commit('shop/unsetLastNameSearch', '', { root: true })
            
            Vue.swal.fire('Operación finalizada', `🎉🎉 La orden ha sido completada: ${invoice}. 🎉🎉`, 'success')
        }
    } catch (error) {
        console.log(error.response)
    } finally {
        console.log('sendCartItems end')
    }
}

export async function cancelOrder({ commit }, idOrder) {

    try {
        commit('shop/setLoadingProcess', true, { root: true })

        /*commit('setLoading', true, { root: true} ) //start loader                
        const response = await Vue.axios({
            method:'POST',            
            url:'/paymentretry/', 
            data: idOrder
        })  
        const respuesta = response && response.data  */

        commit('shop/setEmptyCart', [], { root: true })
        
        showAlertMessage('Cancelación', 'InfoIcon', `🎉🎉 Orden cancelada completada: ${idOrder || '' }. 🎉🎉`, 'success', 4000, 'bottom-right')

    } catch (error) {
        console.log(error.response)
    } finally {
        commit('shop/setLoadingProcess', false, { root: true })
        consoleMsgFinally('shop/cancelOrder', 'La petición para cancelar la orden finalizó')
    }
}