export default {
    categories:{
        hotels: null,
        defaults: null,
        services: null,
    },
    
    airlines:[],
    currencies:[],
    customerTypes:[],
    departaments:[],
    destinations:[],
    discounts:[],
    extraFieldsTypes:[],      
    hotels:[],
    isLoadingCategories: false,    
    languages:[],    
    paymentMethods:[],
    promoCodes:[],
    selectedCategory: null,
    terms:[],
    unavailabledates:[],    
}