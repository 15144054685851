import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import appConfig from '@/modules/appConfig/store'
import auth from '@/modules/auth/store'
import start from '@/modules/start/store'
import shop from '@/modules/shop/store/shop'
import products from '@/modules/shop/store/products'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth','shop','products']
})

export default new Vuex.Store({
  modules: {
    appConfig,        
    auth,
    shop,
    start,    
    products,
  },
  plugins:[vuexLocal.plugin],

  strict: process.env.DEV,
})