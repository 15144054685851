import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import authRouter from '@/modules/auth/router'
import shopRouter from '@/modules/shop/router'
import { validUserLogin } from '../helpers/helpers'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'order-summary' } },                        
    ...authRouter,
    ...shopRouter,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next)=> {
  const requiresAuth = to.matched.some( record => record.meta.requiresAuth)
  const userValidLogin = validUserLogin() // usuario conectado, con canal de venta y un punto de venta
  
  if( requiresAuth && !userValidLogin) {
    next({name: 'auth-login'})
  }else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
