export const menuItemsCompleto = [
  { key: 1, title: 'Marketplace', route: 'sales', icon: 'ShoppingCartIcon' },      
  { key: 2, title: 'Grupos', route: 'groups', icon: 'UsersIcon' },
  { key: 4, title: 'Operación', route: 'operations', icon: 'CommandIcon',},    
  // { key: 5, title: 'Incidencias', route: 'incidents', icon: 'AlignCenterIcon'},
  { key: 6, title: 'FAQ', route: 'faq', icon: 'HelpCircleIcon',}
]

export const menuItemsSinEstancias = [   
  { key: 1, title: 'Marketplace', route: 'sales', icon: 'ShoppingCartIcon' }
]

export const menuItemsDefault = [
  { key: 1, title: 'Marketplace', route: 'sales', icon: 'ShoppingCartIcon' }
]  
