export function setUser(state, user){ //se ejecuta una vez el usaurio haga login
    state.user = user    
    state.isLogged = true    
}

export function logout(state){
    state.user = null
    state.isLogged = false     
}

export function setDataQueryParams(state, queryParams) {
    state.queryParams = queryParams
}

export function setParamsPaxDate(state, data) {

    const { adults, children, date } = data
    
    state.queryParams.adults = adults
    state.queryParams.children = children
    state.queryParams.date = date
}