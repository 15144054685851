import { find } from 'lodash'
import { stringAleatorio, showAlertMessage } from '../../../../helpers/helpers'
import { validateAvailabilityItem } from '../../../../helpers/shopHelper'


export function addProductInCart(state, product) {    
    // con lodash busco si en el cart no se repite en base a su detailId y tipo
    const prodInCart = find(state.cart.items, { detailId: product.detailId, modelType: product.modelType })
    const productClone = Object.assign({}, product)   

    removeKeysProduct(productClone)
    setPropertiesProduct(productClone)

    if (!prodInCart) {
        state.cart.items.push(productClone)
        showAlertMessage(
            'Aggregate product', 'CheckCircleIcon',
            `🎉🎉 Product has been added: ${product.detaildisplay}. `,
            'success', 5000, 'bottom-right'
        )
    }

    if (prodInCart) {
        const { detailId, modelType } = prodInCart
        const idx = state.cart.items.findIndex( p => p.detailId === detailId && p.modelType === modelType)
        state.cart.items[idx].qty = state.cart.items[idx].qty + 1
    }

    setIfRequiredFieldsFilled(state)
    calculateTotal(state)
}

export function addProductInCartInitial(state, product) {    
    // con lodash busco si en el cart no se repite en base a su detailId y tipo
    // const prodInCart = find(state.cart.items, { detailId: product.detailId, modelType: product.modelType })
    const productClone = Object.assign({}, product)
    removeKeysProduct(productClone)
    setPropertiesProduct(productClone)
    
    state.cart.items.push(productClone)        
    
    setIfRequiredFieldsFilled(state)

    calculateTotal(state)
}




export function removeKeysProduct(product) {
    //remuevo keys que no requiero del producto
    delete product.category
        // delete product.coverImage
    // delete product.detail
    delete product.currencyCode
    delete product.type
    delete product.gallery
    delete product.videolink
    delete product.weblink
    delete product.productinfo
    delete product.terms
    delete product.currency
    delete product.hotel
    delete product.metatag
    delete product.location
    // delete product.startDate
    // delete product.startTime
    // delete product.endDate
    // delete product.endDateFormat
    // delete product.endTimeFormat
    // delete product.hotelName
    // delete product.locationName
    delete product.startDateFormat
    delete product.startTimeFormat
}

export function setPropertiesProduct(product) {
    const detaildisplayValid = product.detail ? product.detail : 0
    const detaildisplay = detaildisplayValid != '' ? detaildisplayValid : ''
    product.uuid = stringAleatorio() //les genero string aleatorios
    product.salepriceWithDiscount = product.saleprice
    product.originalSalePrice = product.saleprice
    product.cost = product.cost || "0.00"
    product.itinerario = {}
    product.qty = product.qty || 1
    product.detaildisplay = detaildisplay != "" ? detaildisplay.detaildisplay : product.detaildisplay
    product.orderdetailbeo = setBEOItem(product)        
    product.isIncludenIn = null,
    product.discountApplied = 0
    product.operationdate = product.modelType === 'product' ? null : product.operationdate
    product.operationtime = product.modelType === 'product' ?  '12:00' : product.operationtime 
    product.note = ''
    product.paqueteuuid = product.uuid
    product.isPromo = true
    product.promoName = ''
    product.clientnote = '',
    product.typeDiscount = 'Sin descuento'
    product.orderextraFields = []
    product.requiredFieldsFilled = false

}

export function setBEOItem(product) {
    const { detaildisplay, usabeo } = product
    if (usabeo) {
        return {
            titulo: `BEO  de ${detaildisplay}`,
            backup: null,
            locacion: null,
            comentario: '',
            pax: 0,
            numreserva: '',
            detailbeo: []
        }
    }
    return {
        titulo: '',
        backup: null,
        locacion: null,
        comentario: '',
        pax: 0,
        numreserva: '',
        detailbeo: []
    }
}


export function calculateTotal(state) {
    let sum = 0
    const decimalDiscount = parseFloat(state.cart.totalBreakdown.discountApplied) / 100;
    state.cart.items.forEach((product) => {
        let precio = (product.discountApplied > 0) ? product.salepriceWithDiscount : product.saleprice
        sum += (parseFloat(precio) * parseFloat(product.qty))
    })
    const descuento = sum * decimalDiscount    
    state.cart.totalBreakdown.total = ((Math.round(sum * 100) / 100).toFixed(2)) - descuento        
}


export function setIfRequiredFieldsFilled(state) {
    state.cart.items.forEach((item) => {
        item.requiredFieldsFilled = false
        if (item.categoryName == "Transfer") {
            if (
                item.operationdate && item.operationdate != '' &&
                item.typeDiscount != 'Incluido en el paquete' &&
                item.itinerario.ifrom && item.itinerario.ifrom != '' &&
                item.itinerario.ito && item.itinerario.ito != '' &&
                item.itinerario.pax && item.itinerario.pax != '' &&
                item.itinerario.trip && item.itinerario.trip != ''
            ) {
                item.requiredFieldsFilled = true
            }

            if (item.extrapax != false) {
                item.requiredFieldsFilled = true
            }
        }

        if (item.categoryName == "Flights") {
            if (
                item.operationdate && item.operationdate != ''

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName == "Reservas") {
            if (
                item.NamePrefix && item.NamePrefix != '' &&
                item.GivenName && item.GivenName != '' &&
                item.Surname && item.Surname != '' &&
                item.PhoneNumber && item.PhoneNumber != '' &&
                item.Email && item.Email != '' &&
                item.term && item.term != null &&
                !item.requiresAuthorization

            ) {
                item.requiredFieldsFilled = true
            }
        }
        if (item.categoryName != "Transfer" && item.categoryName != "Flights" && item.categoryName != "Reservas") {
            if (item.operationdate && item.operationdate != '' && item.typeDiscount != 'Incluido en el paquete') {
                item.requiredFieldsFilled = true
            }
        }
        if (item.typeDiscount == 'Incluido en el paquete' && item.isIncludenIn && item.operationdate) {
            item.requiredFieldsFilled = true            
        }
    })
}


export function setLoadingProcess(state, bool) {
    state.isLoadingProcess = bool
}

//seteo del canal de venta
export function setCanalVenta(state, canalVenta) {
    state.canalventa = canalVenta
    state.cart.cliente.sellercanalventa = canalVenta

}
//limpio el canal de venta
export function unsetCanalVenta(state) {
    state.canalventa = null
}
//seteo del punto de venta
export function setPuntoDeVenta(state, puntoDeVenta) {
    state.puntoventa = puntoDeVenta
}
//limpio el punto de venta
export function unsetPuntoDeVenta(state) {
    state.puntoventa = null
}
//pongo el currency default para el client
export function setCurrency(state, currency) {
    state.cart.cliente.currency = currency
}

//para estados BEO en modal edit item cart
export function setDetalleinfoBEO(state, product) {
    const detailId = product.detailId
    const modelType = product.modelType
    let item = state.cart.items.find(item => item.detailId === detailId && item.modelType == modelType)
    item.orderdetailbeo.detailbeo.push(product.extraField)
}

export function setNoteItem(state, data) {
    const { key, notaInterna, notaCliente } = data
    let item = state.cart.items.find(item => item.uuid === key)
    item.note = notaInterna
    item.clientnote = notaCliente
    setIfRequiredFieldsFilled(state)
}

export function setDataProductItem(state, data) {
    const { uuid, cost, saleprice, originalSalePrice, salepriceWithDiscount, operationdate, operationtime, note, clientnote, itinerario, extraField, orderdetailbeo, typeDiscount, isIncludenIn } = data
    let item = state.cart.items.find(item => item.uuid === uuid)

    item.cost = cost
    item.saleprice = saleprice
    item.originalSalePrice = originalSalePrice
    item.salepriceWithDiscount = salepriceWithDiscount

    item.operationdate = validateAvailabilityItem(operationdate) ? operationdate : null
    item.operationtime = operationtime
    item.note = note
    item.clientnote = clientnote
    item.isIncludenIn = isIncludenIn

    //Para itinerario se pone el objeto directamente
    item.itinerario = itinerario
    if( item.itinerario.pickup && item.itinerario.pickup.HH == '' ){
        delete item.itinerario.pickup
    }
    if( item.itinerario.flighttime && item.itinerario.flighttime.HH == '' ){
        delete item.itinerario.flighttime
    }
        //para indicar el descuento
    item.typeDiscount = typeDiscount
        //encabezados del beo
    item.orderdetailbeo.titulo = orderdetailbeo.titulo
    item.orderdetailbeo.backup = orderdetailbeo.backup
    item.orderdetailbeo.locacion = orderdetailbeo.locacion
    item.orderdetailbeo.comentario = orderdetailbeo.comentario
    item.orderdetailbeo.pax = orderdetailbeo.pax
    item.orderdetailbeo.numreserva = orderdetailbeo.numreserva
    item.itinerario.extrapax_amount = 0
    item.itinerario.extrapax_num = 0
    item.saleprice = originalSalePrice

    if (parseFloat(item.pax) < parseFloat(item.itinerario.pax)) {

        if (parseFloat(item.itinerario.pax) > parseFloat(item.paxmax)) {
            item.itinerario.pax = ''
        }

        const diff = item.itinerario.pax == '' ? 0 : Math.abs(item.itinerario.pax - item.pax)
        item.itinerario.extrapax_amount = Math.round(
            (item.paxcost *
                diff *
                100
            ) / 100
        ).toFixed(2);
        item.itinerario.extrapax_num = diff
        item.saleprice = parseFloat(originalSalePrice) + parseFloat(itinerario.extrapax_amount)
    } else {
        item.itinerario.extrapax_amount = 0
        item.itinerario.extrapax_num = 0
        item.saleprice = originalSalePrice
    }
    if (extraField) {
        item.orderdetailbeo.detailbeo.push(extraField) //array de detalles BEO
    }
    calculateTotal(state)
    setIfRequiredFieldsFilled(state)
}

export function setOperationDate(state, data) {
    const { uuid, operationdate } = data
    let item = state.cart.items.find(item => item.uuid === uuid)
    item.operationdate = operationdate
    setIfRequiredFieldsFilled(state)
}

export function setBlackoutDatesProduct(state, data) {
    const { uuid, blackoutDates } = data
    let item = state.cart.items.find(item => item.uuid === uuid)
    item.blackoutDates.disable = blackoutDates
}




//para guardar el nombre del cliente del cart
export function setidUserClient(state, idCliente) {
    state.cart.cliente.idUser = idCliente
}
//para guardar el nombre del cliente del cart
export function setInfoClient(state, clientData) {
    state.cart.cliente.name = clientData.name.trim()
    state.cart.cliente.lastname = clientData.lastname.trim()
    state.cart.cliente.email = clientData.email.trim()
    state.cart.cliente.phone = clientData.phone.trim()
    state.cart.cliente.credit = clientData.credit
}

export function setNameClient(state, nameCliente) {
    state.cart.cliente.name = nameCliente.trim()
}

//para guardar el nombre del cliente del cart
export function setNameAgente(state, nameAgent) {
    state.cart.cliente.customeragent = nameAgent.trim()
}
//para indicar el hotel del cliente
export function setHotelCliente(state, hotel) {
    state.cart.cliente.hotel = hotel
    setIfRequiredFieldsFilled(state)
}
//
export function setLanguageCliente(state, language) {
    state.cart.cliente.language = language
    setIfRequiredFieldsFilled(state)
}

//para indicar el hotel del cliente
export function setSihotCodeHotel(state, sihotCode) {
    state.cart.cliente.hotelsihotcode = sihotCode
}
//para guardar el tipo del cliente del cart
export function setTipoCliente(state, tipoCliente) {
    state.cart.cliente.customertype = tipoCliente
}

//para guardar los apellidos del cliente del cart
export function setLastNameClient(state, lastNameCliente) {
    state.cart.cliente.lastname = lastNameCliente
    state.cart.cliente.lastnameSearch = lastNameCliente

}
//para guardar el email del cliente del cart
export function setEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente.trim()
}
//para guardar el email del cliente del cart
export function unsetEmailCliente(state, emailCliente) {
    state.cart.cliente.email = emailCliente
}

//para guardar el telefono del cliente del cart
export function setPhoneCliente(state, phoneCliente) {
    state.cart.cliente.phone = phoneCliente.trim()
}
//para guardar la forma de pago del cliente en el cart
export function setPaymentMethodClient(state, paymentMethodyCliente) {
    state.cart.cliente.paymentmethod = paymentMethodyCliente
}

//para borar la formas de pago del cliente en el cart
export function setReferenceClient(state, referenceCliente) {
    state.cart.cliente.paymentreference = referenceCliente.trim()
}

export function setPaymentLinkClient(state, paymentLinkCliente) {
    state.cart.cliente.paymentlink = paymentLinkCliente.trim()
}

//para poner valor booleano en la propiedad del estado
export function setMakeRoomCharge(state, boolean) {
    state.cart.makeRoomCharge = boolean
}
//booleano checkbox cargo manual en modal
export function unsetMakeRoomCharge(state) {
    state.cart.makeRoomCharge = false
}
//para poner valor booleano en la propiedad applyManualRoomCharge del estado en cliente
export function setManualRoomChargeCliente(state, value) {
    state.cart.cliente.applyManualRoomCharge = value
}
export function unsetManualRoomChargeCliente(state) {
    state.cart.cliente.applyManualRoomCharge = false
}
//se agrega numero de habitación para el cliente
export function setLastNameChargeClient(state, lastname) {
    state.cart.cliente.lastnameSearch = lastname
}
export function unsetLastNameSearch(state) {
    state.cart.cliente.lastnameSearch = ''
}
//se setea los datos de la habitación en guests
export function setDataRoom(state, data) {
    state.guests = data
}
//para setear la reserva y habitacion del cliente
export function setReservaRoomCharge(state, data) {
    state.cart.numreserva = data.numReserva
    state.cart.room = data.room
    state.cart.cliente.paymentreference = data.numReserva
}

//para poner valor en la propiedad orderCreated del estado
export function setOrderCreated(state, invoice) {
    state.orderCreated = invoice
}
//limpieza del carrito al terminar la venta
export function setEmptyCart(state) {
    state.cart.items = []
    state.cart.cliente.name = ''
    state.cart.cliente.lastname = ''
    state.cart.cliente.email = ''
    state.cart.cliente.phone = ''
    state.cart.totalBreakdown.total = 0.00
    state.cart.totalBreakdown.discountApplied = 0    
    state.orderCreated = null
    delete state.cart.cliente.hotel
}

export function clearCart(state) {
    state.cart.items = []
}


