export function setLoadingProducts( state, bool) {
    state.isloadingProducts = bool
}
export function setProducts( state, products) {
    state.products = products
}

export function setRelatedProducts( state, relatedProducts) {
    state.relatedProducts = relatedProducts
}

// para ocupar en el buscador, filter
export function setFilter(state, data){
    state.filter[data['filter']] = data.value
}
export function setProductDetail(state, product) {
    state.productDetail = product
}

