//start module state Hotels
export function setHotels(state, hotels) {
    state.hotels = hotels
}
//start module state Categories
export function setCategories(state, categories) {
    const { hotels, defaults, services } = categories
    state.categories.hotels = hotels
    state.categories.defaults = defaults
    state.categories.services = services
}

export function setLoadingCategories(state, bool) {
    state.isLoadingCategories = bool
}

//start module state Destinations
export function setDestinations(state, destinations){
    state.destinations = destinations   
}

export function setSelectedCategory(state, category ){
    state.selectedCategory = category
}  
//start module state Currencies
export function setCurrencies(state, currencies) {
    state.currencies = currencies
}

export function setPromoCodes(state, promoCodes){
    state.promoCodes = promoCodes   
}

export function setLanguages(state, languages){
    state.languages = languages   
}

//start module state departamentos 
export function setDepartaments(state, departaments) {
    state.departaments = departaments
}

export function setCustomerTypes(state, customerTypes) {
    state.customerTypes = customerTypes
}

export function setAirlines(state, airlines) {
    state.airlines = airlines
}

//pongo en el estado la lista de metodo de pago del usuario logueado
export function setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods
}

//Terminos y condiciones 
export function setTerms(state, terms){
    state.terms = terms   
}

export function setUnavailableDates(state, unavailabledates){
    state.unavailabledates = unavailabledates   
}





