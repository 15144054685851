export default {    
    cart: {
        cliente: {
            idUser: '',
            name: '',
            lastname: '',                        
            email: '',
            customertype: '',
            hotel: '',
            language: 1,
            customeragent: '',
            phone: '',
            paymentmethod: '',   
            paymentreference:'',
            paymentlink: '',
            sellercanalventa: null
        },
        numreserva: '',
        items: [],
        totalBreakdown: { total: 0.00, discountApplied: 0 },        
    },  
    orderCreated: null,  
    canalventa: null,
    puntoventa: null,
    isLoadingProcess: false,
}