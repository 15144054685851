import store from '../store/index'
// npm i @gtm-support/vue2-gtm --force
const pushImpressionsToDL = () => {   
    let impressions = []
    const user = store.state.auth.user            
    let products = [...store.state.shop.cart.items ] //merge products  
    products = Array.from(new Set(products.map(el => JSON.stringify(el)))).map(el => JSON.parse(el)) //remuevo duplicados
    products.forEach( (item, index) => {
        const obj = {          
            brand: `${ user.name } ${user.lastname}`,
            category:item.categoryName,
            date: item.startDate || '',
            hotel: item.hotelName || '',
            id: item.id || item.detailId,
            location: item.locationName || '',
            name: item.name,
            position: index + 1,
            price: item.detailSelected.saleprice,
            variant: item.detailSelected?.detaildisplay || '' ,
        }        
        impressions.push(obj)
    })  
    
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push({currencyCode: 'USD', impressions})
}

const productClickDL = ( product, index ) => {
    const user = store.state.auth.user        
    const { name, id, categoryName, detailSelected, locationName, hotelName } = product              
    const productClick = {
        event: "productClick",
        ecommerce:{
            click:{
                actionField: { list: 'Search Results' },
                products: [{
                    name,
                    id,
                    price: detailSelected.saleprice,
                    brand: `${ user.name } ${user.lastname}`,
                    category: categoryName || '',
                    variant: detailSelected?.detaildisplay || '',
                    position: index + 1,
                    hotel: hotelName || '',
                    location: locationName || ''
                }]
            }
        }
    }
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(productClick)
}

const addToCartDL = (product, index ) => {
    const user = store.state.auth.user
    const { name, id, categoryName, locationName, hotelName, saleprice, detaildisplay } = product
    const addToCart = {
        event: 'addToCart',
        ecommerce: {
            currencyCode: 'USD',
            add: {
                products: [{
                    name,
                    id,
                    price: saleprice,
                    brand: `${ user.name } ${user.lastname}`,
                    category: categoryName || '',
                    variant: detaildisplay || '',
                    position: index + 1,
                    hotel: hotelName || '',
                    location: locationName || ''
              }]
            }
        }
    }
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(addToCart)  
}

const removeFromCartDL = product  => {
    const { name, id, categoryName, detaildisplay, saleprice} = product
    const removeFromCart = {
        event: 'removeFromCart',
        ecommerce: {
            currencyCode: 'USD',
            remove: {
                products: [{
                    name,
                    id,
                    price: saleprice,                    
                    category: categoryName || '',
                    variant: detaildisplay || '',                    
                    quantity: 1
              }]
            }
        }
    }
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(removeFromCart)
}

const stepOneDL = () =>{   
    const itemsCart = store.state.shop.cart.items     
    const user = store.state.auth.user
    let products = []    
    itemsCart.forEach( (item, index) => {
        const obj = {
            brand: `${ user.name } ${user.lastname}`,                    
            category: item.categoryName || '',
            date: item.startDate || '',
            id: item.id,
            name: item.name,
            position: index + 1,
            price: item.saleprice,
            quantity: item.qty,
            variant: item.detaildisplay || '',
        }        
        products.push(obj)
    })
    const data = {
        event: 'checkout',
        ecommerce: { checkout: { actionField: { step: 1 }, products } }
    }
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(data)
    

}

const stepTwoDL = () =>{
    const itemsCart = store.state.shop.cart.items   
    const user = store.state.auth.user
    const cliente = store.state.shop.cart.cliente   
    let products = []
    const { name, lastname, email, customertype, phone } = cliente 
    const customer = { name, lastname, email, customertype, phone } 
    itemsCart.forEach( (item, index) => {
        const obj = {
            brand: `${ user.name } ${user.lastname }`,                    
            category: item.categoryName || '',
            date: item.startDate || '',
            id: item.id,
            price: item.saleprice,
            name: item.name,
            position: index + 1,
            quantity: item.qty,
            variant: item.detaildisplay || '',                    
        }        
        products.push(obj)
    })

    const data = {
        event: 'checkout',
        ecommerce: { checkout: { actionField: { 'step': 2 }, products, customer } }
    }

    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(data)
    
}
const finishStepDL = (nuAut, importe, success, nbResponse, reference) => {

    const user = store.state.auth.user           
    const itemsCart = store.state.shop.cart.items     
    const order = store.state.shop.orderCreated  
    const cliente = store.state.shop.cart.cliente   
    const totalBreakdown = store.state.shop.cart.totalBreakdown.total

    let products = []      
    const { name, lastname, email, customertype, phone } = cliente 
    const customer = { name, lastname, email, customertype, phone } 
    itemsCart.forEach( (item, index) => {
        const obj = {
            brand: `${ user.name } ${user.lastname }`,                    
            category: item.categoryName || '',
            coupon: '',
            date: item.startDate || '',
            id: item.id,
            name: item.name,
            position: index + 1,
            price: item.saleprice,
            quantity: item.qty,
            variant: item.detaildisplay || '',
        }
        products.push(obj)
    })

    const data = {
        event:'purchase',        
        ecommerce: {
          currencyCode: 'USD', 
          purchase:{
            actionField: {
              numAuthorization: nuAut || '', 
              revenue: importe || '',                
              coupon: '',
              success,
              nbResponse: nbResponse || '',
              reference: reference || '', 
              revenue: totalBreakdown,                
              email,
              id: order,
            },
            products,
            customer
          }           
        }
    }
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(data)
}

const finishStepTotalZeroDL = () =>{

    const user = store.state.auth.user           
    const itemsCart = store.state.shop.cart.items     
    const order = store.state.shop.orderCreated  
    const cliente = store.state.shop.cart.cliente   
    const totalBreakdown = store.state.shop.cart.totalBreakdown.total

    let products = []      
    const { name, lastname, email, customertype, phone } = cliente 
    const customer = { name, lastname, email, customertype, phone } 
    itemsCart.forEach( (item, index) => {
        const obj = {
            brand: `${ user.name } ${user.lastname }`,                    
            category: item.categoryName || '',
            coupon: '',
            date: item.startDate || '',
            id: item.id,
            name: item.name,
            position: index + 1,
            price: item.saleprice,
            quantity: item.qty,
            variant: item.detaildisplay || '',
        }
        products.push(obj)
    })

    const data = {
        event:'purchase',        
        ecommerce: {
          currencyCode: 'USD', 
          purchase:{
            actionField: {
              coupon: '',
              email,
              id: order,
              nbResponse:  '',
              numAuthorization:  '', 
              reference: '',            
              revenue: totalBreakdown,                
              success: true,
            },
            products,
            customer
          }           
        }
    }    
    window.dataLayer?.push({ ecommerce: null }) //nulo primero
    window.dataLayer?.push(data)
    
}

export {
    pushImpressionsToDL,
    productClickDL,
    addToCartDL,
    removeFromCartDL,
    stepOneDL,
    stepTwoDL,
    finishStepDL,
    finishStepTotalZeroDL
}