import Vue from 'vue'
import store from '../../../store/index' //para acceder a state para isLogged
import {  consoleMsgFinally } from '../../../helpers/helpers'

export async function fetchHotels( data) {
    const { onlyDingusCode, all , showInWeb } = data
    try {
        const response = await Vue.axios({
            url: '/catalogs/hotels/'
        })
        const payload = response && response.data 
        if( onlyDingusCode ){
            const hotels = payload.filter( hotel => hotel.dingusCode != null )
            return hotels
        } 
        
        if( all ){            
           return payload
        } 

        if( showInWeb ){
            const hotels = payload.filter( hotel => hotel.showinweb )
            return hotels
        } 
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchHotels', 'La petición para obtener los hoteles se ha terminado')
    }
}

export async function fetchPaymentMethods() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/paymentmethod/'
        })
        const payload = response && response.data  
        const creditCard = payload.find( fp => fp.id === 1 )    
        return { creditCard, paymentMethods: payload}
    } catch (error) {
        console.log(error)
    } finally {
        console.log('start/fetchPaymentMethods ha terminado')
    }
}

export async function fetchCurrencies() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/currency/'
        })
        const payload = response && response.data      
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        console.log('start/fetchCurrencies ha terminado')
    }
}




export async function fetchDepartaments() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/departamentos/'
        })
        const payload = response && response.data
        //ordeno alfabeticamente los nombres de los departamentos
        payload.sort((a, b) => a.name.localeCompare(b.name))
        payload.forEach((departament) => {
            departament.value = departament.id,
            departament.text = departament.name
            delete departament.id
            delete departament.deleted
            delete departament.name
            delete departament.createdate
        })
        return payload
    } catch (error) {
        console.log( error )
    } finally {
        consoleMsgFinally('start/fetchDepartaments', 'La petición para obtener los departamentos se ha terminado')
    }
}

export async function fetchAirlines() {

    try {
        const response = await Vue.axios({
            url: '/catalogs/aerolineas/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchAirlines', 'La petición para obtener las aerolineas se ha terminado')

    }
}

export async function fetchTypeClients() {

    try {
        const response = await Vue.axios({
            url: '/catalogs/tipocliente/'
        })
        const payload = response && response.data
        payload.sort(function(a, b){
            if(a.name < b.name) { return -1}
            if(a.name > b.name) { return 1}
            return 0
        })
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchTypeClients', 'La petición para obtener los tipos de clientes se ha terminado')
    }
}

//Obtener terminos y condiciones por canal
export async function fetchTerms(context, datos) {
    const { typeTerms, idLanguage} = datos
    console.log( datos )
    try {
        const response = await Vue.axios({
            url: '/catalogs/terminosycondiciones/',
            params: {
                typeTerms,
                idLanguage
            }
        })
        const payload = response && response.data        
        return payload
    } catch (error) {
        console.log( error)
    } finally {
        consoleMsgFinally('start/fetchTerms', 'La petición para obtener los terminos se ha terminado')
    }
}

export async function fetchPromoCodes() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/promoCodes/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchPromoCodes', 'La petición para obtener los promocodes se ha terminado')
    }
}

export async function fetchLanguages() {
    try {
        const response = await Vue.axios({
            url: '/catalogs/language/'
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchLanguages', 'La petición para obtener los lenguajes se ha terminado ')
    }
}

//Obtener fechas no disponibles por  producto
export async function fetchUnavailableDates({ commit }, datos) {
    // console.log( datos )
    const { idP, qty, fini  } = datos
    try {
        const response = await Vue.axios({
            url: '/allotment/notavailable/',
            params: {
                idP,
                qty,
                fini
            }
        })
        const payload = response && response.data

        commit('start/setUnavailableDates', payload, { root: true })
    } catch (error) {
        console.log(error)
    } finally {
        consoleMsgFinally('start/fetchUnavailableDates', 'La petición para obtener los terminos se ha terminado')
    }
}