import Vue from 'vue'
import store from '@/store/index' //para acceder a state
import { setParamsGetProducts, formatItems, formatItemProduct, formatProduct, formatEvent, formatExperience } from '@/helpers/helpers'
import {  fetchCurrencies, fetchPaymentMethods } from '@/modules/start/store/actions'

export async function getInitialContent(context, params){
    context.commit('setLoadingProducts', true) //set State loader  
    const content = await Promise.all([        
        fetchCurrencies(), fetchProducts( params ), fetchProductDetail(context, params), fetchPaymentMethods()
    ])                            
    const [ currencies, products, product, paymentMethods ] = content   
    const { creditCard } = paymentMethods  
    context.commit('start/setCurrencies', currencies, { root: true }) // set State
    context.commit('setProducts', products )   // set State
    context.commit('shop/setPaymentMethodClient', creditCard.id, { root: true } )   // set State
    context.commit('setLoadingProducts', false)  // set State loader  
    return { currencies, products, product, paymentMethods }
}

export async function getInitialProducts(context, requestArray) {
    let items = []
    let requests = requestArray.map( payload => getProduct(payload) ) //generate array of promises to make requests
    const products = await Promise.all(requests) // return array of products as a result
    products.forEach(product => {
        if(product){
            items.push(product)
        }
    });
    return items
}

//para peticiones asyncronas para obtener lista de productos best seller
export async function fetchProducts( parametros ) {
    const { type, endPoint, language } = parametros
    const idseccanal =  store.state.auth.user?.sectionCanal?.id 
    const params = setParamsGetProducts( parametros, idseccanal )          
    try {        
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: {...params, language}
        })        
        const payload = response && response.data        
        return formatItems( payload, type ) 
    } catch (error) {
        console.log( error )
    } finally {    
        console.log('fetchProducts ha terminado')
    }
}

//peticiones para obtener lista de productos por categorias
export async function fetchProductsByCategories(context, idCategory) {    
    const idseccanal =  store.state.auth.user?.sectionCanal?.id       
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { idcat: idCategory, idseccanal }
        })
        const payload = response && response.data        
        return formatItems( payload, 'products' ) 

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchProductsByCategories end')

    }
}


//para peticiones asyncronas para obtener lista de productos fetchRelatedProducts
export async function fetchRelatedProducts(context, parametros ) {
    const idseccanal =  store.state.auth.user?.sectionCanal?.id           
    try {        
        const response = await Vue.axios({
            url: `/products/`,
            params: { bs: 1, idseccanal }
        })        
        const payload = response && response.data        
        return formatItems( payload, 'products' ) 
    } catch (error) {
        console.log( error )
    } finally {    
        console.log('fetchRelatedProducts end')
    }
}

export async function fetchProductDetail(context, payload) {

    const { idProducto, endPoint,language, type} = payload    
    const idseccanal =  store.state.auth.user?.sectionCanal?.id
    // const endPointRequest = endPoint === 'productswebsites' ? 'products' : endPoint
    try {
        const response = await Vue.axios({
            url: `/${endPoint}/`,
            params: { id: idProducto, idseccanal, language}
        })
        const payload = response && response.data
        if(payload.length > 0 ){            
            const item = formatItemProduct(payload[0], type) 
            delete item.type
            delete item.category
            delete item.active
            delete item.eventdetail
            delete item.productinfo
            delete item.products
            delete item.gallery
            
            return item 
        } else {
            return []
        }
    } catch (error) {
        console.log( error )        
    } finally {
        console.log('fetchProductDetail end')
    }
}

export async function getProduct(context, payload) {
    const { idProducto, idSecCanal } = payload    
    try {
        const response = await Vue.axios({
            url: `/products/`,
            params: { id: idProducto, idseccanal:idSecCanal }
        })
        const payload = response && response.data        
        const item =  payload && payload.length > 0 ? formatProduct(payload[0]) : null
        return item
    } catch (error) {
        console.log( error )        
    }
}

export async function getEvent(context, payload) {
    const { idEvent, idSecCanal } = payload    
    try {
        const response = await Vue.axios({
            url: `/events/`,
            params: { id: idEvent, idseccanal:idSecCanal }
        })
        const payload = response && response.data      
        const item = payload && payload.length > 0 ? formatEvent(payload[0]) : null
        return item
    } catch (error) {
        console.log( error )        
    } finally {
        console.log('getEvent end')
    }
}

export async function getExperience(context, payload) {
    const { idExperience, dateRange, adults, children, idSecCanal,  } = payload    
    try {
        const response = await Vue.axios({
            url: `/productsRates/`,
            params: { id: idExperience, dateRange, adults, children, idseccanal:idSecCanal }
        })
        const payload = response && response.data      
        const item = payload && payload.length > 0 ? formatExperience(payload[0]) : null
        return item
    } catch (error) {
        console.log( error )        
    }  finally {
        console.log('getExperience end')
    }
}



export async function fetchUnavailableDates(context, datos) {
    const { idP, qty, fini  } = datos
    try {
        const response = await Vue.axios({
            url: '/allotment/notavailable/',
            params: { idP, qty, fini }
        })
        const payload = response && response.data
        return payload
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchUnavailableDates', 'end')
    }
}

