import { toJson, toDecimal, stringAleatorio, camposRequeridosPorLLenar, showAlertMessage } from './helpers'
import store from '../store/index'

const processItemsCart = ( items, clientData ) => {
    const detail = []
    items.forEach((prod) => {
        
        const precio = (parseInt(prod.discountApplied) > 0) ? prod.salepriceWithDiscount : parseFloat(prod.originalSalePrice) 
        const unitPrice = toDecimal(prod.originalSalePrice)
        const qty = prod.qty
        const discount = toDecimal((prod.originalSalePrice - prod.salepriceWithDiscount) * prod.qty)
        const discounttype = prod.typeDiscount || 'noDiscount'
        const total = toDecimal(precio * prod.qty)
        const note = prod.note.substring(0, 254)
        const clientnote = prod.clientnote.substring(0, 254)
        const orderextraFields = prod.orderextraFields ? toJson(prod.orderextraFields) : []
        const modelType = setTypeModel( prod.id, prod.modelType, prod.detailId )
        const product = modelType.product
        const pack = modelType.package
        const event = modelType.event
        const booking = modelType.booking
        const productDetail = modelType.productDetail
        const packageDetail = modelType.packageDetail
        const eventDetail = modelType.eventDetail
        const detailitinerary = setItinerary(prod, clientData)
        const orderdetailbeo = toJson(prod.orderdetailbeo)
        //operationTime
        const operationtime = prod.operationtime
        const serviciosihot = prod.servicesihot || null

        const experience = prod.experience || null
        const experiencecost = prod.experiencecost || 0
        const experienceType = prod.experienceType || 1 
        const experienceContract = prod.experienceContract || null
        
        //si fecha operacion viene nulo no se pone en el detalle        
        let objetoDetalle = createObjectPayload(prod, unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, experience, experiencecost, experienceType, experienceContract)
       
        //push para el detalle
        detail.push(objetoDetalle)
    })
    return detail
}

const setTypeModel = (id, type, detailId) => {
    if (type === "product") {
        return {
            product: id,
            package: null,
            event: null,
            booking: null,
            productDetail: detailId,
            packageDetail: null,
            eventDetail: null
        }
    } 
 
    if (type === "event") {
        return {
            product: null,
            package: null,
            event: id,
            booking: null,
            productDetail: null,
            packageDetail: null,
            eventDetail: detailId
        }
    } 

    if ( type === "experience" ) {
        return {          
            experience: id,
            product:null,
            package: null,
            event: null,
            booking: null,
            productDetail: null,
            packageDetail: null,
            eventDetail: null
        }
    } 
 
}

const setItinerary = (item, clientData) => {
    let detailitinerary = {}
    if ( item.itinerario ) {        
        let hourPickup = ''
        let flightTime = ''
        if( item.itinerario.pickup ){
            const hour = item.itinerario.pickup.HH
            const minutes = item.itinerario.pickup.mm
            hourPickup = ( hour != '' && minutes != '') ? hour + ':' + minutes : ''
        }
        if( item.itinerario.flighttime ){
            const hour = item.itinerario.flighttime.HH
            const minutes = item.itinerario.flighttime.mm
            flightTime = ( hour != '' && minutes != '') ? hour + ':' + minutes : ''
        }
        const flight = item.itinerario.flight || ''
        const flighttime = flightTime
        const pickup = hourPickup
        const ifrom = item.itinerario.ifrom || ''
        const ito = item.itinerario.ito || ''
        const pax = item.itinerario.pax || ''
        const benefitedName = item.itinerario.nameBenefi || clientData.name
        const benefitedLastName = item.itinerario.lastNameBenefi || clientData.lastname
        const airline = item.itinerario.airline ? item.itinerario.airline.name : ''
        const trip = item.itinerario.trip || ''
        detailitinerary = { flight, flighttime, ifrom, ito, pax, benefitedName, benefitedLastName, airline, pickup, trip }
    }
    return detailitinerary
}

const createObjectPayload = (item, unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, experience, experiencecost, experienceType, experienceContract) => {
    const detalle = { unitPrice, qty, discount, discounttype, total, note, clientnote, orderextraFields, product, package: pack, event, booking, productDetail, packageDetail, eventDetail, detailitinerary, operationtime, orderdetailbeo, serviciosihot, experience, experiencecost, experienceType,experienceContract }

    if (item.operationdate) {
        detalle.operationdate = item.operationdate
    }
    const itinerarioValido = isValidItinerary(detailitinerary)

    if (!itinerarioValido) {
        delete detalle.detailitinerary
    }

    const beoValido = isValidOrderDetailBEO(orderdetailbeo)

    if (!beoValido) {
        delete detalle.orderdetailbeo
    }

    const extraFieldsValido = isValidaOrderExtraFields(orderextraFields)

    if (!extraFieldsValido) {
        delete detalle.orderextraFields
    }
    return detalle
}

const createObjectBooking = (objetoDetalle, item) => {
    delete objetoDetalle.detailitinerary
    delete objetoDetalle.operationtime
//Datos para el order booking 
    objetoDetalle.orderbooking = {
        adults: item.adultos,
        authorizationUser: item.idUserAuthorizeRate || null ,
        children: item.ninosTotal,
        dateAuthorization: item.dateAuthorization || null,
        datein: item.dateIn,
        dateout: item.dateOut,
        email: item.Email,
        givenname: item.GivenName,
        hotel: item.idHotel,
        inclusions: stringInclusionBooking(toJson(item.inclusions)),
        inforates: formatInfoRates(item.infoRates),
        isAuthorization: item.idUserAuthorizeRate ? true : false,
        isRateLimit: item.isRateLimit || false,
        language: item.idioma,
        mealplancode: item.MealPlanCodes,        
        multimedias: toJson(item.multimedias) || null,
        nameprefix: item.NamePrefix,
        nights: item.nights,
        ninos: toJson(item.ninos),
        partial: item.partial,
        phonenumber: item.PhoneNumber,
        promotioncode: item.promotionCode,
        rateInit: item.rateInit,
        rateManual: item.rateManual ? item.rateManual : 0,
        rateplancode: item.rateplancode,
        rateplanid: item.rateplanid,
        rateplanname: item.rateplanname,
        roomtypecode: item.roomCode,
        roomtypename: item.roomtypename,
        surname: item.Surname,
        termscondition: item.term ? item.term.id : null,
        total: item.saleprice,
        urlAuthorization: item.urlAuthorization,
        urlimage: item.img,
        vendorcode: item.vendorcode,
    }
    return objetoDetalle
}

const formatInfoRates = ( rates ) => {
    const list = []
    rates.forEach((rate) => {
        const { DateIn, DateOut, Rate  } = rate
        const item = { checkin: DateIn, checkout: DateOut, rate: Rate }
        list.push(item)
    })
    return toJson(list)  
}

const stringInclusionBooking = (inclusions) => {
    let stringInclusion = ``
    for (let i = 0; i < inclusions.length; i++) {
        const saltoLinea = i == (inclusions.length - 1) ? '' : '\n'
        stringInclusion += `Inclusiones: ${inclusions[i].name}\nDetail: ${inclusions[i].detaildisplay}${saltoLinea}`
    }
    return stringInclusion
}


const isValidItinerary = (itinerario) => {
    const { ifrom, ito, pax, trip } = itinerario
    if (ifrom == "" || ito == "" || pax == "" || trip == "") {
        return false
    } else {
        return true
    }
}

const isValidOrderDetailBEO = ( dataBEO ) => {
    const { backup, comentario, locacion, titulo } = dataBEO
    if (backup == null && comentario == "" && locacion == null && titulo == "") {
        return false
    } else if (backup == null || locacion == null || comentario != "" || titulo != "") {
        return true
    }
}


const isValidaOrderExtraFields = (extraFields) => {
    if (extraFields.length == 0) {
        return false
    } else {
        return true
    }
}

const createPayloadToSave = (client, state, getters, detail, clientDataBackend ) => {
    //datos para el cliente tomado desde el formulario + datos del state en el modulo shop
    const { paymentmethod, paymentreference, paymentlink, idUser, hotel, customertype, customeragent, applyManualRoomCharge, language } = client  
    //de los getters from module sho  
    const grossSale = toDecimal( parseFloat( getters.totalDiscountOnAllProducts ) + parseFloat( getters.totalBreakdown ) )
    const discount = getters.totalDiscountCuenta
    const totalSale = getters.totalBreakdown
    //datos del cliente retornados desde el backend
    const { id } = clientDataBackend
    //del estado shop
    const currency = state.cart.cliente.currency.currencyid
    const numreserva = state.cart.numreserva
    const canalventa = state.canalventa // canal de venta
    const puntoventa = state.puntoventa // punto de venta
    const seccioncanal = store.state.auth.user.sectionCanal.id
    return {
        grossSale, paymentreference,  tax: "0.00", discount, totalSale, customer: id, paymentmethod, currency,  paymentlink, saleUser: idUser, hotel, orderStatus: 1, detail, customertype, customeragent, cargomanual: applyManualRoomCharge, numreserva, hotelsihotcode:null, canalventa, puntoventa, language, seccioncanal
    }
}


const formatBookings = ( rooms, isPromo, fromTab) => {
    rooms.forEach( room => {
        room.detailSelected = room.rates.length ? room.rates[0] : []         
        room.priceInit = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'        
        room.priceInitDefault = room.rates.length ? room.rates[0].AmountAfterTax : '0.00'        
        room.fromTab =  fromTab       
        room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }                                                                                 
        room.keyRoom = stringAleatorio()  
        room.showingRates = true
        room.rates.forEach( ( rate ) => { 
            const esPromo = isPromo ? isPromo : false
            rate.allowPriceMatch = true                         
            rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }           
            rate.isPromo = esPromo                                                           
            rate.manualPrice = ''                                  
            rate.priceInit = rate.AmountAfterTax
            rate.priceMatchTax = ''

        })
    }) 
    return rooms    
}

const formatRoomPromoCode = ( room, datosRequest, message, isPromo ) => {  
    const { priceInit, keyRoom, allowPriceMatch, manualRate } = datosRequest
    
    room.detailSelected = room.rates.length ? room.rates[0] : [] 
    room.detailSelected.divisa = { currencyid: 1, code: "USD", value: "1.00" }          
    room.priceInit =  priceInit       
    room.keyRoom = keyRoom || ''  
    room.showingRates = true       
    //agrego cosas al listado de rates
    room.rates.forEach( ( rate ) => {         
        const priceMatchTax = !message ? rate.AmountAfterTax : "0.00"            
        const esPromo = isPromo ? isPromo : false
        rate.divisa = { currencyid: 1, code: "USD", value: "1.00" }  
        rate.priceMatchTax = priceMatchTax
        rate.priceInit = priceInit || ''
        rate.manualPrice = manualRate                  
        rate.allowPriceMatch = allowPriceMatch        
        rate.isPromo = esPromo
    })
    return room
}


const makePayloadRoomRate = ( data ) => {
    const { previousRooms, newRoom, keyRoom } = data        
    const room = previousRooms.find( room => room.keyRoom === keyRoom ) 
    room.rates = newRoom.rates   
    room.priceInit = newRoom.priceInit  
    room.detailSelected = newRoom.detailSelected      
    return toJson(previousRooms)
}

const filtroDestinos = ( payload ) => {
    const { typeService, destinations } = payload
    const arrivalsOrigin = ['Aeropuerto Cancun']
    const arrivalsDestiny = ['The Fives Beach', 'The Fives Oceanfront', 'The Fives Downtown']
    const departureOrigin = ['The Fives Beach', 'The Fives Oceanfront', 'The Fives Downtown']
    const departureDestiny = ['Aeropuerto Cancun']
    const interHotel = ['The Fives Beach', 'The Fives Oceanfront', 'The Fives Downtown']
    let resultOrigin = [] 
    let resultsDestiny = [] 
        
    destinations.forEach( item => {
        if (arrivalsOrigin.includes(item.value) && typeService == 'Arrival' ){
            resultOrigin.push(item)
        }
        if (arrivalsDestiny.includes(item.value) && typeService == 'Arrival' ){
            resultsDestiny.push(item)
        }
        if (departureOrigin.includes(item.value) && typeService == 'Departure' ){
            resultOrigin.push(item)
        }
        if (departureDestiny.includes(item.value) && typeService == 'Departure' ){
            resultsDestiny.push(item)
        }
        if (interHotel.includes(item.value) && typeService == 'Inter Hotel' ){
            resultOrigin.push(item)
            resultsDestiny.push(item)
        }
        if (typeService == 'Open Service' ){
            resultOrigin.push(item)
            resultsDestiny.push(item)
        }
        if (typeService == 'Transporte Colaborador' ){
            resultOrigin.push(item)
            resultsDestiny.push(item)
        }
    })

    return {resultOrigin, resultsDestiny}
}

const horaVuelo = ( payload ) => {
    const { typeService, timeflight, timepickup} = payload
    const sametime = timepickup

    if (timeflight.length > 0) {
        sametime = timeflight
    }
    
    return sametime
}

const itemsCartNotReady = ( products ) => {
    let items  = []
    products.forEach( prod => {
        const { detaildisplay } = prod
        //campos requeridos llenados
        if(!prod.requiredFieldsFilled){
            const item = {key: stringAleatorio(), name: detaildisplay,  fields : camposRequeridosPorLLenar(prod)}
            items.push( item )
        }
    })
    return items

}

const validateAvailabilityItem = ( date ) => {
    const datesNotAvailable = store.state.start.unavailabledates
    const result = datesNotAvailable.find( d => d.date === date )
    
    const isAvailable = result ? false: true
    if(!isAvailable){
        showAlertMessage(`Date not available`, 'InfoIcon', `😰😰 For this date, this product is not available, try another date`,'danger', 3000, 'bottom-right')
    }
    return isAvailable
}

export {
    processItemsCart,
    setTypeModel,
    setItinerary,
    createObjectPayload,
    createObjectBooking,
    isValidItinerary,
    isValidOrderDetailBEO,
    isValidaOrderExtraFields,
    createPayloadToSave,
    formatBookings,  
    formatRoomPromoCode,
    makePayloadRoomRate,
    filtroDestinos,
    horaVuelo, 
    itemsCartNotReady,
    validateAvailabilityItem,    
}